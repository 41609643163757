.all-contents {
  background: #eef1f6;
}
.body-contents {
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
}

.select-item {
  & select.form-select {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px !important;
    border: none;
    background-color: #fff;
    background-image: url(../assets/img/icon_triangle_down.png);
    background-repeat: no-repeat;
    background-position: right 1.25rem center;
    background-size: auto;
    cursor: pointer;
  }
}

.btn-submit {
  background: #475c85 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
// .reservations{}
.reservation {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
}
// .reservation-item{}
.reservation-link {
  display: block;
  background-image: url(../assets/img/icon_Arrow_RightSmall_gry.png);
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: auto;
  padding-right: 60px;
}
.reservation-item__check {
  & input[type='checkbox'] {
    transform: scale(2);
    accent-color: #475c85;
  }
}

.reservation-item__user {
  width: 340px;
  flex-basis: 340px;
  white-space: nowrap;
}
.avatar {
  width: 64px;
  height: 64px;
  // border:1px solid #ff0000;
  & img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 999px;
  }
}
.user__cond {
  border: 1px solid #ccc;
}
.user__cond__unapproved {
  border-color: #eb5247;
  color: #eb5247;
}

.reservation-item__facility,
.reservation-item__equip,
.reservation-item__car {
  flex-basis: 176px;
  overflow: hidden;
  white-space: nowrap;
  // border:1px solid #ff0000;
  & dd {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.reservation-item__charge {
  white-space: nowrap;
  flex-basis: 100px;
}

.reservation-item__start,
.reservation-item__end {
  white-space: nowrap;
  flex-basis: 135px;
}

.reservation-item__id {
  white-space: nowrap;
  flex-basis: 80px;
}

.reservation-item__parts {
  margin: 0;
  padding: 0;
  & dt {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
  }
}
