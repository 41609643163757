@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Noto Sans CJK JP', 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer components {
  /* placeholderの設定 */
  [type='text'].form-input,
  [type='email'].form-input,
  [type='tel'].form-input,
  [type='password'].form-input,
  [type='number'].form-input,
  textarea.form-textarea {
    @apply placeholder-txblack-alpha-300;
  }

  /* サイズの設定 */
  [type='text'].form-input,
  [type='email'].form-input,
  [type='tel'].form-input,
  [type='password'].form-input,
  [type='number'].form-input,
  [type='date'].form-input,
  select.form-select {
    @apply h-14;
    @apply px-4;
  }

  /* borderの設定 */
  [type='text'].form-input,
  [type='email'].form-input,
  [type='tel'].form-input,
  [type='password'].form-input,
  [type='number'].form-input,
  [type='date'].form-input,
  select.form-select,
  textarea.form-textarea {
    @apply appearance-none;
    @apply border;
    @apply border-base-600;
    @apply rounded;
  }

  /* フォーカース時のborderの設定 */
  [type='text'].form-input:focus,
  [type='email'].form-input:focus,
  [type='tel'].form-input:focus,
  [type='password'].form-input:focus,
  [type='number'].form-input:focus,
  [type='date'].form-input,
  select.form-select:focus,
  textarea.form-textarea:focus,
  [type='checkbox'].form-input:focus,
  [type='radio'].form-input:focus {
    @apply border-none;
    @apply outline-none;
    @apply ring-2;
    @apply ring-blue-500;
    @apply border-blue-500;
  }

  /* selectの設定 */
  select.form-select {
    background-color: #fff;
    @apply bg-select;
    @apply bg-no-repeat;
    background-position: right 0.25rem center;
    background-size: 2.5rem 2.5rem;
    @apply pl-4;
    @apply pr-12;
  }

  /* checkboxの設定 */
  [type='checkbox'].form-input {
    @apply appearance-none;
    @apply w-6;
    @apply h-6;
    @apply rounded;
    @apply border;
    @apply border-main-700;
  }
  [type='checkbox'].form-input:checked {
    @apply bg-main-700;
    @apply bg-checkbox;
    @apply bg-no-repeat;
    @apply bg-center;
    background-size: 1.5rem 1.5rem;
  }

  /* radioの設定 */
  [type='radio'].form-input {
    @apply appearance-none;
    @apply w-6;
    @apply h-6;
    @apply rounded-full;
    @apply border;
    @apply border-main-700;
  }
  [type='radio'].form-input:checked {
    @apply bg-radio;
    @apply bg-no-repeat;
    @apply bg-center;
    background-size: 1rem 1rem;
  }
  [type='radio'].form-input:disabled {
    @apply bg-gray-100;
    @apply border-main-500;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.stack-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
