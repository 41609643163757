#header {
  width: 100%;
  background-color: #ffffff;
  background-image: url(../assets/img/logo-everiwa-landscape.svg);
  background-size: auto 36px;
  background-position: left 16px top 14px;
  background-repeat: no-repeat;
  height: 63px; /*shadow分をプラス*/
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

#header #info {
  display: flex;
  float: right;
  margin-right: 16px;
  margin-top: 10px;
  padding: auto;
  height: 44px;
}

#user_info {
  display: flex;
  margin-right: 16px;
  height: 44px;
}

#user_name {
  margin-right: 16px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
}

#user_name img {
  margin: auto;
}

#user_icon {
  display: flex;
}

#user_icon img {
  margin: auto;
}

/*------------------------------------
  container_main
  ------------------------------------*/
#container_main {
  width: 100%;
  position: relative;
  height: calc(100vh - 63px);
  overflow-y: scroll;
}

#container_main_section {
  display: flex;
  flex-flow: column;
  padding-bottom: 40px;
  min-height: calc(100vh - 123px);
}

#container_main_section-2 {
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 123px);
}

/*------------------------------------
  main
  ------------------------------------*/
#main {
  margin: 0px 32px auto 32px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}

#main p {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
}

.line {
  height: 14px;
  line-height: 14px;
  margin: auto 14px;
  width: 1px;
  background-color: #364563;
}

hr {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  margin: 0;
  padding: 0;
}

// pagenation
.pagenation {
  & .page-item {
    line-height: 1;
  }
  & .page-link {
    display: block;
    width: 40px;
    //height: 44px;
    border-radius: 8px;
    border: 1px solid #999999;
    line-height: 44px;
    text-align: center;
    &.current {
      border: 1px solid #364563;
      background: #364563;
      color: #fff;
    }
    &.omission,
    &.page-link__prev,
    &.page-link__next {
      border: none;
      width: auto;
    }
    &.omission {
      padding: 0 4px;
    }
    &.page-link__prev,
    &.page-link__next {
      position: relative;
      width: 24px;
      height: 44px;
      text-indent: -9999px;
      &::after {
        content: '';
        position: absolute;
        display: block;
        top: calc(50% - 10px);
        width: 24px;
        height: 24px;
      }
    }
    &.page-link__prev {
      &:after {
        left: 0;
        background: url(../assets/img/icon_left_small.png) no-repeat;
        background-size: cover;
      }
    }
    &.page-link__next {
      &:after {
        right: 0;
        background: url(../assets/img/icon_Arrow_RightSmall_gry.png) no-repeat;
        background-size: cover;
      }
    }
  }
}

.disclosure-item {
  display: block;
  padding-right: 60px;
  position: relative;
  &::after {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 5px;
    right: 18px;
    background-image: url(../assets/img/icon_Arrow_RightSmall_gry.png);
  }
  &.expand {
    &::after {
      transform: rotate(90deg);
    }
  }
}

.disclosure-item-no-content {
  display: block;
  padding-right: 60px;
  position: relative;
}
