.item__check {
  & input[type='checkbox'] {
    transform: scale(2);
    accent-color: #475c85;
  }
}

.dli-chevron-down {
  display: inline-block;
  vertical-align: middle;
  color: rgba(71, 92, 133);
  line-height: 1;
  width: 0.75rem;
  height: 0.75rem;
  border: 0.113rem solid currentColor;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  transform: translateY(-25%) rotate(135deg);
  margin-right: 10px;
}
